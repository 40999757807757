import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import {
  Button,
  Columns,
  Column,
  Container,
  Content,
  Hero,
  HeroBody,
  Level,
  LevelItem,
  Title,
  Subtitle
} from 'bloomer';
import Typist from 'react-typist';
import Fade from 'react-reveal/Fade';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import BigProfilePic from '../components/big-profile-pic';

// Theme
import { ThemeContext, getOppositeTheme } from '../contexts/theme';

const About = props => {
  const { theme } = useContext(ThemeContext);

  const { data, location } = props;
  const { title: siteTitle, keywords } = data.site.siteMetadata;

  return (
    <Layout location={location}>
      <SEO title="About" keywords={keywords || []} />
      <Hero isColor={theme} className="is-fullheight-with-navbar">
        <HeroBody>
          <Container>
            <Fade left>
              <React.Fragment>
                <Title>{siteTitle}</Title>
                <Subtitle>
                  <Typist startDelay={1000}>
                    A software engineer from Japan{' '}
                    <span role="img" aria-label="jsx-a11y/accessible-emoji">
                    🇯🇵
                    </span>
                  </Typist>
                </Subtitle>
              </React.Fragment>
            </Fade>
          </Container>
        </HeroBody>
      </Hero>
      <Hero isFullHeight isColor={theme}>
        <HeroBody>
          <Container>
            <Columns isVCentered>
              <Column>
                <Fade left>
                  <React.Fragment>
                    <Title>
                      <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        👋🏻
                      </span>
                    </Title>
                    <Content>
                      <p>
                      みなさまのビジネスの拡大のためにITがお手伝いできることがあると思います。ただ、ITを利用しようとしてもご利用される方の環境・経験で合う技術が実際には異なり、
                      同じ技術を導入しても大きく効果をあげる場合もあればその逆に足を引っ張ってしまうことも多々あるのです。
                      
                      私たちは、お客様のご利用経験・環境に合わせて業務改善やビジネスへのチャレンジに必要なITのご提案をさせていた機体と思っております。

                      
                      その実態がよくわからない方
                      
                      利用者側の環境・経験に合わせて
                      実際には話に聞いていても具体的にどうITと向かい合えば良いかわからない方から現状の業務をITでご支援すること改善したりビジネスの新しいチャンスをITで
                      
                      活用することでいままでの業務効率を改善したり、また新たなビジネスへの挑戦をお手伝いができればと思っております。ITを利用することでどのような効果が

                      </p>
                      <p>
                        このサイト自体も私が構築し提供しており、ベースの技術は以下のもので構成しています。特徴としては無料のサービスを活用することで安価に
                        <ul>
                        <li>
                            <a
                              href="https://reactjs.org"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="React"
                            >
                              React
                            </a>{' '}
                            UI設計
                        </li>
                        <li>
                            <a
                              href="https://gatsbyjs.org"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Gatsby"
                            >
                              Gatsby
                            </a>{' '}
                            サイト生成ツール
                        </li>
                        <li>
                            <a
                              href="https://netlify.org"
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Netlify"
                            >
                              Netlify
                            </a>{' '}
                            コンテンツ配信基盤
                        </li>                        
                        </ul>
                      </p>
                      <hr />
                      <p>
                        Have a look at my resume (<i>coming soon</i>) or contact
                        me!
                      </p>
                    </Content>
                    <Level isMobile>
                      <LevelItem>
                        <Button disabled>Resume</Button>
                      </LevelItem>
                      <LevelItem>
                        <Button
                          isColor="info"
                          href="https://t.me/dennismrl"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Telegram"
                        >
                          Telegram
                        </Button>
                      </LevelItem>
                      <LevelItem>
                        <Button
                          isColor={getOppositeTheme(theme)}
                          href="mailto:dennis@morello.dev"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="E-mail"
                        >
                          E-mail
                        </Button>
                      </LevelItem>
                    </Level>
                  </React.Fragment>
                </Fade>
              </Column>
              <Column>
                <Fade right>
                  <BigProfilePic
                    className="is-hidden-mobile"
                    style={{ margin: 'auto 0 auto auto' }}
                  />
                  <BigProfilePic
                    className="is-hidden-tablet"
                    style={{ margin: '2rem auto 0 auto' }}
                  />
                </Fade>
              </Column>
            </Columns>
          </Container>
        </HeroBody>
      </Hero>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        keywords
        author
      }
    }
  }
`;
